<template>
  <v-content>
    <v-breadcrumbs :items="items" large>
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item
          :to="item.to"
          :disabled="item.disabled"
          :exact="item.exact"
        >
          {{ $t(item.text) }}
        </v-breadcrumbs-item>
      </template>
    </v-breadcrumbs>
    <v-container class="">
      <v-snackbar
        v-model="snackbar.snackbar"
        :color="snackbar.color"
        :timeout="callbackResponse.timeout"
        top
      >
        {{ callbackResponse.message }}
        <v-btn dark text @click="snackbar.snackbar = false">Close</v-btn>
      </v-snackbar>
      <div slot="table-actions">
        <v-btn color="primary" @click.stop="createForm = true">{{
          $t("employee_form")
        }}</v-btn>
        <!-- <v-btn class="success float-right mx-4" @click.stop="onDownload"><v-icon>get_app</v-icon>Export</v-btn>
                 <v-btn class="primary float-right mx-4" @click.stop="onImport"><v-icon>publish</v-icon></v-btn>
                 <v-file-input class="col-sm-2 float-right" v-model="formdata.import" label="Import File" outlined dense></v-file-input>-->
      </div>
      <v-dialog
        v-model="dialogCustomerInformation"
        hide-overlay
        max-width="500px"
      >
        <CustomerInformation
          v-if="dialogCustomerInformation"
          :hotelEmployeeID="hotelEmployeeID"
          @formResponse="onResponse"
          @formResponseClose="onResponseClose"
        />
      </v-dialog>
      <v-expansion-panels hover>
        <v-expansion-panel class="fix-for-nepali-label">
          <v-expansion-panel-header>{{
            $t("filter")
          }}</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-container fluid>
              <v-row align="start" justify="start">
                <v-col class="d-flex text-center" cols="12" sm="12">
                  <v-spacer></v-spacer>
                  <v-text-field
                    v-model="serverParams.empName"
                    clearable
                                dense
                        outlined
                    class="col-sm-6 col-md-4"
                  >
                    <template v-slot:label>
                      {{ $t("employee_name") }}
                    </template>
                  </v-text-field>
                  <v-spacer></v-spacer>
                </v-col>
                <v-col class="d-flex text-center" cols="12" sm="12">
                  <v-spacer></v-spacer>
                  <v-btn
                    class="mx-2"
                    dark
                    small
                    color="primary"
                    @click="loadItems"
                  >
                    <v-icon dark>search</v-icon>
                    {{ $t("search") }}
                  </v-btn>
                  <v-spacer></v-spacer>
                </v-col>
              </v-row>
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <vue-good-table
        mode="remote"
        @on-page-change="onPageChange"
        @on-sort-change="onSortChange"
        @on-column-filter="onColumnFilter"
        @on-per-page-change="onPerPageChange"
        :totalRows="totalRecords"
        :isLoading.sync="isLoading"
        :pagination-options="{ enabled: true, mode: 'records' }"
        :rows="rows"
        :columns="columns"
      >
        <template slot="table-column" slot-scope="props">
          <span>
            {{ $t(props.column.label) }}
          </span>
        </template>
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field == 'actions'">
            <v-icon small class="mr-2" @click="editItem(props.row)"
              >edit</v-icon
            >
            <v-icon small @click="deleteItem(props.row)">delete</v-icon>
          </span>
        </template>
      </vue-good-table>
    </v-container>

    <v-dialog
      v-model="createForm"
      hide-overlay
      transition="dialog-bottom-transition"
      max-width="500px"
    >
      <stepper-form-component
        v-if="createForm"
        :dialogue="createForm"
        :pendingData="pendingData"
        @formResponse="onResponse"
        @formResponseClose="onResponseClose"
      ></stepper-form-component>
    </v-dialog>

    <v-dialog
      v-model="editForm"
      hide-overlay
      transition="dialog-bottom-transition"
      max-width="500px"
    >
      <editForm
        v-if="editForm"
        :hotelEmployeeID="hotelEmployeeID"
        @formResponse="onResponse"
        @formResponseClose="onResponseClose"
      />
    </v-dialog>

    <v-dialog
      v-model="showForm"
      hide-overlay
      transition="dialog-bottom-transition"
      max-width="500px"
    >
      <showForm
        v-if="showForm"
        :hotelEmployeeID="hotelEmployeeID"
        @formResponseClose="onResponseClose"
      />
    </v-dialog>
    <v-dialog max-width="500px" v-model="dialogDelete">
      <deleteListData
        :deleteFormData="deleteFormData"
        @formResponse="onResponse"
      ></deleteListData>
    </v-dialog>
  </v-content>
</template>

<script>
import axios from "axios";
import createForm from "./create";
import editForm from "./edit";
import showForm from "./show";
import CustomerInformation from "./customerInformation";
import deleteListData from "@/components/deleteModal";

export default {
  name: "EmployeeData",
  props: ["success"],
  components: {
    "stepper-form-component": createForm,
    CustomerInformation,
    editForm,
    showForm,
    deleteListData,
  },
  data() {
    return {
      deleteFormData: {},
      dialogDelete: false,
      callbackResponse: {
        timeout: 5000,
      },
      hotelEmployeeID: null,
      items: [
        {
          text: "dashboard",
          disabled: false,
              to: "NationalParkDashboard",
          exact: true,
        },
        {
          text: "Employee",
          disabled: true,
        },
      ],
      message: false,
      totalDesserts: 0,
      apiData: [],
      pendingData: [],
      loading: true,
      createForm: false,
      editForm: false,
      showForm: false,
      dialogCustomerInformation: false,
      dialogEditCustomerInformation: false,
      snackbar: {
        snackbar: false,
        text: "Internal Server Error,Please Contact Admin",
        timeout: 200000,
        menu2: false,
        save_button: false,
        next_button: true,
        success: false,
        error: false,
        number_of_second_form: 1,
        price: 0,
        color: "",
      },
      serverParams: {
        search: "",
        columnFilters: {},
        sort: [
          {
            field: "employeeID",
            type: "desc",
          },
        ],
        page: 1,
        perPage: 10,
        empName: "",
      },
      columns: [
        { label: "name", field: "empName" },
        { label: "email", field: "empEmail" },
        { label: "address", field: "empAddress" },
        { label: "contact_no", field: "empPhone" },
        { label: "actions", field: "actions" },
      ],
      rows: [],
      province: [],
      division: [],
      filterEditedData: {},
      dialogData: {},
      formdata: [],
      totalRecords: 0,
      isLoading: false,
    };
  },
  mounted() {
    this.loadItems();
  },
  destroyed() {
    console.log(this); // There's practically nothing here!
  },
  methods: {
    async onImport() {
      var form = new FormData();

      form.append("formFile", this.formdata.import);
      let ImportResponse = await axios.post("Hotel/Import", form);
      if (ImportResponse.data.success) {
        this.loadItems();
        this.formdata.import = null;
      } else {
        return false;
      }
    },
    async onDownload() {
      await axios({
        url: "Hotel/Download",
        method: "POST",
        responseType: "blob",
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "ImportHotelFile.xlsx"); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
    },
    onResponse(val) {
      if (val) {
        this.dialogCustomerInformation = false;
        this.snackbar.snackbar = true;
        this.callbackResponse.message = val.message;
        this.snackbar.color = val.color;
        this.editForm = false;
        this.createForm = false;
        this.dialogDelete = false;
        console.log("val", val);
        this.loadItems();
      }
    },
    onResponseClose() {
      console.log("here");
      this.dialogCustomerInformation = false;
      this.editForm = false;
      this.createForm = false;
      this.showForm = false;
    },
    editItem({ employeeID }) {
      this.editForm = true;
      this.hotelEmployeeID = employeeID;
    },
    showItem(item) {
      this.showForm = true;
      this.hotelEmployeeID = item.hotelEmployeeID;
    },
    closeForm() {
      this.editForm = false;
      this.showForm = false;
    },
    customerInformationMethod(props) {
      console.log(props);
      this.hotelEmployeeID = props.hotelEmployeeID;
      this.dialogCustomerInformation = true;
    },
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },
    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.loadItems();
    },
    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.loadItems();
    },
    onSortChange(params) {
      this.updateParams({
        sort: [
          {
            type: params[0].type,
            field: params[0].field,
          },
        ],
      });
      this.loadItems();
    },
    onColumnFilter(params) {
      this.updateParams(params);
      this.loadItems();
    },
    async loadItems() {
      this.loading = true;

      let param = {
        PageSize: this.serverParams.perPage,
        PageNo: this.serverParams.page,
        OrderType: this.serverParams.sort[0].type,
        OrderBy: this.serverParams.sort[0].field,
        EmpName: this.serverParams.empName,
      };
      axios
        .post("Employee/GetEmployeeDetailListAsync", param)
        .then((response) => {
          this.loading = false;
          this.rows = response.data.data;
          console.log("response.data", response.data);
          console.log("this.rows", this.rows);
          this.totalRecords = response.data.totalCount;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    deleteItem({ employeeID }) {
      this.dialogDelete = true;
      this.deleteFormData.url =
        "Employee/DeleteEmployeeDetailByIDAsync/" + employeeID;
    },
  },
};
</script>

<style lang="scss" scoped>
.pasForm {
  margin-top: 4em;
}

.square-checkout {
  height: 1.5em;
  width: 1.5em;
  background-color: #69f0ae;
}

.square-not-checkout {
  height: 1.5em;
  width: 1.5em;
  background-color: #f3e5f5;
}
</style>
